body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.speech-bubble-left {
  position: relative;
  background: #8A9EB1;
  border-radius: .4em;
  box-shadow: 0 0 12px -5px rgba(0,0,0,0.75);
}

.speech-bubble-left:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 0.25em solid transparent;
  border-right-color: #8A9EB1;
  border-left: 0;
  margin-top: -0.25em;
  margin-left: -0.25em;
}

.speech-bubble-right {
  position: relative;
  background: #8A9EB1;
  border-radius: .4em;
  box-shadow: 0 0 12px -5px rgba(0,0,0,0.75);
}

.speech-bubble-right:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 0.25em solid transparent;
  border-left-color: #8A9EB1;
  border-right: 0;
  margin-top: -0.25em;
  margin-right: -0.25em;
}